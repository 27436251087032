.ButtonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.25em;
  margin-left: -0.125em;

  * {
    margin: 0 0.125em;
  }
}
