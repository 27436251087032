@import '../../../variables.scss';

.InstancesTable {
  width: 100%;
  text-align: left;
  column-gap: 0.1em;

  th,
  td {
    padding: 0.5em;
    width: auto;
  }

  th.Min,
  td.Min {
    width: 1%;
    white-space: nowrap;
  }

  th {
    div {
      display: flex;

      * {
        padding: 0 0.1em;
      }

      .FilterButton {
        cursor: pointer;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:nth-child(2n) {
        background-color: $primary-lighter-84;
        color: $primary-lighter-84-text;
      }

      &:nth-child(2n + 1) {
        background-color: $primary-lighter-96;
        color: $primary-lighter-96-text;
      }

      &:hover {
        background-color: $primary-lighter-12;
        color: $primary-lighter-12-text;
      }
    }
  }
}
