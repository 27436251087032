@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import './variables';

#root {
  min-height: 100vh;
}

.MuiPopover-root {
  right: 0;
  left: auto;
  .MuiPaper-root {
    top: 56px !important;
    right: 8px !important;
    left: auto !important;

    ul.MuiList-root {
      ul {
        padding: 0;
        li {
          padding: 0 16px;
          list-style: none;
          width: auto;
          min-width: 112px;
          height: 48px;
          display: grid;
          align-items: center;
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            color: $dark-grey;
            i {
              padding-right: 8px;
            }
          }
          &:hover {
            background-color: $primary;
            a { color: $white; }
          }
          [class*="Navbar_active"] {
            color: $primary;
          }

        }

        li:first-child {
          height: auto;
          padding-bottom: 8px;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.LogOutBtn {
  color: $error-red;
}

.ValidationText.Error {
  margin-bottom: 10px;
  color: $primary-red;
}
