input[type="date"] {
    width: 260px;
}

.request_otp {
    width: 140px !important;
}

.btnotp_request {
    display: inline-block;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 0.225rem 0.55rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: white;
}

.form_container {
    text-align: left;
    margin-left: 120px;
}

.acceptance {
    text-align: left;
    margin-left: 100px;
}

.btnotp_request:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn_submit {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: white;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
    position: absolute;
    left: 300px;
}

.btn_submit:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn_download_pdf {
    display: inline-block;
    background-color: #FF9800;
    border-color: #FF9800;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: white;
    position: absolute;
    left: 350px;
}

.btn_download_pdf:hover {
    color: #fff;
    background-color: #FFA726;
    border-color: #FF9800;
}

.heading {
    font-size: 18px;
    font-weight: bold;
}

.errormsg {
    color: red;
    position: absolute;
    left: 200px;
}