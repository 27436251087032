  input[type="date"] {
    width: 260px;
    text-align: center;
  }

  .request_otp {
    width: 140px !important;
  }

  .saved {
    visibility: hidden;
  }

  .otpForm {
    margin-top: 90px;
    visibility: hidden;
  }

  .correct {
    visibility: visible;
  }
  
  .btnotp_request {
    display: inline-block;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 0.225rem 0.55rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: white;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 2px 16px;
    width: 45%;
    margin-top: 0.5%;
    border-radius: 0.4rem;
    background-color: #0081c6;
  }

  
  .card_open {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 2px 16px;
    padding-right: 2px 16px;
    width: 97%;
    margin-top: 0.5%;
    border-radius: 0.4rem;
    border-color: #0081c6;
    margin-bottom: 0.5%;
    text-align: center;
  }

  .save_button {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin-top: 50px;
    color: white;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
  }
  
  .save_button:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .otpSubmit {
    margin-top: 50px;
    padding-left: 15%;
  }

  .info_msg {
    color: red;
}
  
  .card_heading {
    font-size: auto;
    font-weight: bold;
    width: fit-content;
    text-align: center;
    margin-bottom: 16px;
    background-color: #0081c6;
    
  }
  
  .input {
    border-radius: 0.25rem;
    border-style:ridge ;
    align-items: flex-end;
    text-align: center;
  }
  
  .form_container {
    text-align: left;
    margin-left: 20px;
  }
  
  .acceptance {
    text-align: left;
    margin-left: 100px;
  }
  
  .btnotp_request:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }

  .input_controller {
    text-align: center;
    width: 80%;
    border-radius: 0.25rem;
    border-style:ridge ;
    align-items: flex-end;
    text-align: center;
    margin-left: 20%;
  }

  .btn_submit {
    border-radius: 50px;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin: 1%;
    margin-bottom: 10px;
    margin-right: 2%;
    color: black;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
  }
  .btn_submit:hover {
    color: #fff;
    background: #218838;;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .btn_submit_confirmation {
    border-radius: 50px;
    margin-left: 47%;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-right: 2%;
    color: black;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
  }

  .btn_submit_confirmation:hover {
    color: #fff;
    background: #218838;;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .confirmation_msg {
    padding-top: -6%;
    padding-left: 30%;
    padding-bottom: 25%;
  }

  .btn_submit_o_t_p {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin-top: 50px;
    margin-bottom: 80px;
    color: white;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
    position: absolute;
    margin-left: 100px;
  }
  
  .btn_submit_o_t_p:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn_download_pdf {
    display: inline-block;
    background-color: #FF9800;
    border-color: #FF9800;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: white;
    position: absolute;
    left: 350px;
  }

  .btn_download_pdf:hover {
    color: #fff;
    background-color: #FFA726;
    border-color: #FF9800;
  }

  .heading {
    font-size: auto;
    font-weight: bold;
    width: fit-content;
    margin-left: 30%
  }

  .errormsg {
    color: red;
  }

  @import '../../variables';

.FormWrapper {
  padding: 24px;
  font-family: 'Roboto';
  font-size: 16px;

  display: grid;
  // justify-content: center;

  .btn_footer {
    top: 50%;
    left: 50%;
  }

  .form {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45% ;
    grid-row-gap: 8px;
    row-gap: 8px;
    grid-column-gap: 16px;
    column-gap: 16px;
    justify-content: space-around;
    align-items: start;

    h2 {
      font-family: 'Roboto Condensed';
      font-size: 18pt;
      text-transform: uppercase;
      color: #005e91;
      font-weight: 700;
    }

    label {
      text-align: left;
    }

    .item1 {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    input[type='text'], select {
      height: 36px;
      padding: 0 12px 0 16px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
      width: 100%;
      text-align: center;
    }


    input[type='date'], select {
      height: 36px;
      padding: 0 12px 0 16px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
      width: 100%;
      text-align: center;
    }

    .invis {
      color: white;
    }

    input[type='checkbox'], select {
      height: 37.5px;
      padding: 0 12px 0 16px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
      width: 50%;
      text-align: end;
    }
    input[type='time'], select {
      height: 36px;
      padding: 0 12px 0 16px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
      width: 100%;
      text-align: center;
    }

    
  
    
    .input {
      border-radius: 0.25rem;
      text-align: center;
      width: 103.5%;
      height: 36px;
      padding: 0 12px 0 16px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
      padding-block: 5px;
      text-align: center;
    }

    input {
        border-radius: 0.25rem;
        text-align: center;
        width: 100.5%;
        height: 30px;
        padding: 0 12px 0 16px;
        border: 1px solid primary-lighter-96-text;
        border-radius: 5px;
        justify-self: start;
        font-family: 'Roboto';
        font-size: 16px;
        color: $primary;
        justify-self: stretch;
        padding-block: 5px;
        text-align: center;
    }
    
    textarea {
      height: 70px;
      padding: 12px;
      border: 1px solid primary-lighter-96-text;
      border-radius: 5px;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
    }

    .top {
      align-self: start;
    }
    .column {
      flex-direction: column;
    }

    .NavBar {
      width: 100%;
      display: grid;
      grid-template-columns: 45% 45% ;
      grid-row-gap: 8px;
      row-gap: 8px;
      grid-column-gap: 16px;
      column-gap: 16px;
      justify-content: space-around;
      align-items: start;

      h2 {
        font-family: 'Roboto Condensed';
        font-size: 18pt;
        text-transform: uppercase;
        color: #005e91;
        font-weight: 700;
      }
    }

    .TermsWrapper {
      grid-column: 1 / span 2;
      p {
        line-height: 2;
      }
      input[type='checkbox'] {
        width: 25px;
        height: 25px;
        vertical-align: bottom;
        margin-right: 10px;
      }
      h3 {
        font-family: 'Roboto Condensed';
        font-size: 14pt;
        text-transform: uppercase;
        color: #47a3d3;
        font-weight: 700;
      }
      input.ContractUndersigned {
        width: 300px;
        height: 25px;
        padding: 0 8px;
        border: none;
        border-bottom: 2px solid $primary-text-75;
        justify-self: start;
        font-family: 'Roboto';
        font-size: 16px;
        color: $primary;
        justify-self: stretch;
      }
    }
    

    .SaveBtn, .DownloadBtn, .QuoteValidateOtpBtn {
      grid-column: 1 / span 2;
      width: 50%;
      padding: 0 16px 0 12px;
      height: 36px;
      border-radius: 5px;
      font-family: 'Roboto Condensed';
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: white;
      background-color: $primary;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: center;
      gap: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      letter-spacing: 1px;

      &:hover {
        box-shadow: 0 5px 10px rgba(0,0,0,0.22);
        cursor: pointer;
      }
    }
    .DownloadBtn {
      background-color: #005e91;
    }

    .OtpSendBtn {
      background-color: #005e91;
      padding: 0 16px 0 12px;
      height: 36px;
      border-radius: 5px;
      font-family: 'Roboto Condensed';
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: white;
      background-color: $primary;
      border: none;
      margin-left: 5px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      letter-spacing: 1px;
    }

    .QuoteValidateOtpBtn {
      background-color: $primary-green;
    }

    .ErrorMsg {
      color: $primary-red;
    }

    .InfoMsg {
      color: $primary-red;
    }
  }

  .QuoteCompleteCard {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 50px 40px;
    width: 60%;
    margin-top: 0.5%;
    border-radius: 0.4rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background-color: #005e91;
  }

  .QuoteCompleteCardHeading {
    font-size: 16px;
    font-weight: bold;
    width: fit-content;
    margin-bottom: 16px;
  }

  .LoaderIcon i {
    animation: spin 1s linear infinite;
    color: $primary;
    font-size: 32px;
  }

  [class*="multiValue"] {
    background-color: $primary;
    color: white;
    border-radius: 25px;
    margin: 0 4px 4px 0;
    div:first-child {
      font-size: 14px;
      padding: 4px 4px 4px 12px;
      color: white;
    }
    div:nth-child(2) {
      cursor: pointer;
      &:hover {
        background-color: red;
        color: white;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }
}

.firstOtpForm {
  margin: 2%;
  padding-left: 3%;
  border-radius: 20px;
  padding-bottom: 0.5%;
  background: rgb(255, 255, 255);
  box-shadow:  23px 23px 33px #7d7d7d,
             -23px -23px 33px #f7f7f7;
  .info_msg {
    margin-top: -0.5%;
    color: rgb(34, 108, 237);
    margin-bottom: 1%;
  }
  .btn_submit_otp {
    border-radius: 50px;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-right: 2%;
    color: black;
    transition: color 0.15s ease-in-out;
    background-color: #5cb85c;
    border-color: #28a745;
  }
  .btn_submit_otp:hover {
    color: #fff;
    background: #218838;;
    background-color: #218838;
    border-color: #1e7e34;
  }
  .resend_otp {
    border-radius: 50px;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-right: 2%;
    color: black;
    transition: color 0.15s ease-in-out;
    background-color: #2172d6;
    border-color: #0391f6;
  }
  .resend_otp:hover {
    color: #fff;
    border-radius: 50px;
    padding: 0.375rem 0.75rem;
    background: #2172d6;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-right: 2%;
    transition: color 0.15s ease-in-out;
    background-color: #2172d6;
    border-color: #0391f6;
  }
  .errormsg {
    color: red;
  }
}
