@import '../../../variables';

.Icon {
  padding: 10px;
}

.Nav {
  display: grid;
	grid-direction: column;
	grid-template-columns: 1fr auto 1fr;
	grid-gap: 8px;
	grid-template-areas:
    'logo navlinks morebtn';
  justify-items: start;
	align-items: center;
  padding: 0 16px;

	height: 88px;
  background-color: $light-grey;
	box-shadow: -3px -3px 7px rgba(255, 255, 255, 0.78), 3px 3px 7px rgba(0, 0, 0, 0.15);
	color: $dark-grey;
	font-family: $heading-font;
  position: sticky;
  top: 0;
	z-index: 2;

  .NavLogo {
    grid-area: logo;
    img {
      width: 190px;
  		max-width: 100%;
  	}
  }

	.NavLinks {
    grid-area: navlinks;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    grid-gap: 4px;
    @include ButtonPanel;
    justify-self: stretch;
		a {
			display: flex;
			align-items: center;
			@include MenuBtn;
      justify-content: center;
      i {
        padding-right: 8px;
      }
      .HelpNav {
        font-size: 16px;
      }
    }
	}

}

.MoreMenuBtn {
  grid-area: morebtn;
  @include MenuBtnMobile;
  display: grid;
  justify-self: end;
  justify-content: center;
  align-items: center;
  color: black;
	margin-left: 8px;
  grid-column: 1 /5;
}

.MsgIndicator {
  @include MsgIndicator;
}

.MenuSelect:hover {
  cursor: pointer;
}
