.spinner_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner_loading:before {
  content: "";
  height: 90px;
  width: 90px;
  border-width: 8px;
  border-style: solid;
  border-color: #2180c0 #ccc #ccc;
  border-radius: 100%;
  animation: rotation .7s infinite linear;
  display: inline-block;
}

.spinner_text {
  position: relative;
}

.spinner_loading p {
  word-wrap: break-word; 
  text-align: center;
}

.refresh_page {
    height: 36px;
    border-radius: 5px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 16px;
    color: white;
    border: none;
    letter-spacing: 1px;
    background-color: #0081c6;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}