@import '../../../variables';

.MoreMenuBtnContainer {
  height: 40px;
  width: 40px;
  grid-area: morebtn;
  justify-self: end;
}

.MoreMenuBtn {
  @include MenuBtnMobile;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userName {
  padding: 6px 16px;
  min-width: 150px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.menuIcon {
  margin-right: 8px;
}
