.Instance {
  .Messages {
    display: flex;
    justify-content: space-evenly;

    div {
      display: flex;
    }
  }
}
