.AnsibleJobs {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;

  .AnsibleJob {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5em 1em;
    margin: 0.25em 0;

    .JobLine {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        padding: 0 0.5em;
      }
    }
  }
}
