/*.Touched {
  background-color: lightblue;
}
*/
@import '../../variables.scss';

.FieldInput {
  place-self: center stretch;
  width: 100%;
  border-bottom: 1pt solid #47a3d3;
}

input[type="date"] { 

  width: 100% !important;
  color-scheme: light;
}

.FieldInputBox {
  place-self: center stretch;
  height: 20px;
}

.FieldLabel {
  place-self: center stretch;
  margin-bottom: 10px;
  width: 100%;
}

[class*="control"] {
  background: $white;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: $dark-grey;
  font-family: $body-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: transparent;
  padding: 8px 16px;
  width: 70%;

  [class*="ValueContainer"], [class*="Input"], [class*="placeholder"] {
    padding: 0;
    margin: 0;
  }
  [class*="Input"] input {
    box-shadow: none;
    color: transparent;
    height: auto;
  }
  [class*="multiValue"] {
    background-color: $primary;
    color: $white;
    border-radius: 25px;
    margin: 0 4px 4px 0;
    div:first-child {
      color: $white;
      font-family: $body-font;
      font-size: 14px;
      padding: 4px 4px 4px 12px;
    }
    div:nth-child(2) {
      cursor: pointer;
      &:hover {
        background-color: $error-red;
        color: $white;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }
  [class*="Date"] {
    box-shadow: none;
    height: auto;
  }
}

.SubmitBtn {
  @include PrimaryBtn;
}

.FieldGroupSingleRow {
  grid-template-columns: 1fr;
  grid-template-areas:
    'fieldlbl'
     'fieldinput'
    'fieldinfo';
  width: 100%;
  grid-gap: 8px;
  margin-bottom: 24px;
  justify-items: center;
  align-items: start;
}
.FieldGroupSingle_File {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    'uploadfilesbtn'
    'uploaded';
  width: 100%;
  grid-gap: 8px;
  margin-bottom: 8px;
  justify-items: flex-start;
  align-items: start;
}

.FilesUpload {
  padding-top: 5px;
  grid-area: uploadfilesbtn;
  width: 100%;
  .FilesBtn {
    @include SecondaryBtn;
    padding: 8px 16px;
  }
  input {
    display: inline;
    min-height: 36px;
  }

  .UploadWarningBtn {
    @include ButtonPanel;
    background-color: $error-red;
    padding-top: 5%;
	}

	.UploadSucessBtn {
		@include ButtonPanel;
    background-color: $success-green;
    padding-top: 5%
	}
}
