@import '../../../variables.scss';

.Many2OneInput {
  display: flex;
  align-items: center;

  input {
    flex-grow: 2;
  }

  svg {
    margin-left: 0.25em;
    color: red;
  }
}

.FieldInput {
  place-self: center stretch;
  width: 100%;
}

.FieldInput {
  place-self: center stretch;
  width: 100%;
}

.Many2OneModal {
  color: black;
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  height: 80%;

  display: flex;
  flex-direction: column;

  .TableContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  tr {
    padding: 4px;
    cursor: pointer;
    user-select: none;
  }

  td {
    padding: 4px;
  }

  tr:hover {
    color: $primary-lighter-12-text;
    background-color: $primary-lighter-12;
  }
}
