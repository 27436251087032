html,
body,
#root,
.App,
main {
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ValueGrid {
  display: grid;
  grid-template-columns: max-content auto;
  row-gap: 0.25em;
  column-gap: 1.5em;
  justify-content: start;
}

.Page {
  text-align: left;
  padding: 0 2em 2em 2em;

  h1 {
    text-align: center;
  }
}

@import './variables';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: 'Roboto', 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*input,
select {
  justify-self: stretch;
  flex-grow: 1;
}
button,
input,
select,
.Input {
  border-radius: 5px;
  border: 1px solid lightgrey;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.4);
  font-size: 1em;
  padding: 0.8em 1.2em;
  margin: 0.1em 0.25em;
}
button.Error,
input.Error,
select.Error {
  border: 1px solid rgba(255, 0, 0, 0.3);
}
.Error {
  box-shadow: 0 2px 4px 0 rgba(255, 0, 0, 0.1),
    0 3px 2px -2px rgba(255, 0, 0, 0.2), 0 1px 10px 0 rgba(255, 0, 0, 0.4);
}*/

/*.active {
  background-color: #004b74;
  color: $white;
}*/

/* NEUMORPHISM SKIN */
body {
	font-family: $body-font;
	background-color: $light-grey;
	color: $dark-grey;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $dark-grey-25;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $dark-grey-50;
    border-radius: 20px;
  }
}

p {
  font-size: 16px;
  text-align: justify;
  margin: inherit;
  line-height: 1.5;
  &:not(:last-child)  {
    margin-bottom: 1em;
  }
}

h1, h2, h3 {
	font-family: $heading-font;
	font-style: normal;
	font-weight: bold;
  line-height: auto;
	color: $dark-grey;
	text-transform: uppercase;
}

h1 {
	font-size: 24px;
  color: $primary;
  margin: inherit;
}
h2 {
	font-size: 18px;
  color: $dark-grey-75;
  margin: inherit;
}
h3 {
	font-size: 16px;
  color: $dark-grey-50;
  margin: inherit;
}

input {
	height: 48px;
	background: $white;
	border-radius: 5px;
	color: $dark-grey;
	font-family: $body-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	border: transparent;
	padding: 0 16px;
  justify-self: stretch;
  width: 100%;

	&::placeholder {
		color: $dark-grey-50;
	}
	&:focus-visible {
		outline: 1px solid $primary;
	}
  &[type="file"] {
    background-color: transparent;
    height: auto;
    padding: inherit;
    box-shadow: none;
  }
}

select {
  height: 48px;
  background: $white;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: $dark-grey;
	font-family: $body-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	border: transparent;
	padding: 0 16px;
  justify-self: stretch;
  width: 100%;

  &::placeholder {
		color: $dark-grey-50;
	}
	&:focus-visible {
		outline: 1px solid $primary;
	}
  option:hover {
     background-color: $primary;
     color: $white;
  }
}

textarea {
  @extend input;
  min-height: 70px;
  padding: 16px;
}

button {
	@include Button;

	&:disabled {
		opacity: 0.5;
	}
}

a {
	color: $primary;
}

.italic {
  font-style: italic;
}

.bolder {
  font-weight: bolder;
}

.AlignRight {
  text-align: right;
}

.BannerText {
  background-color: #A9DFBF;
  font-size: 16px;
  text-align: justify;
  margin: inherit;
  line-height: 1.5;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.ValidationText {
  display: flex;
  align-items: center;
}

.small-icon {
  margin-right: 8px;
}

.error-message {
  display: inline-block;
}
