.ModalPage {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #333e;
  color: white;
  backdrop-filter: blur(15px) grayscale(100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
