.Login {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2em;
  width: 650px;
  max-width: 80%;
  margin: auto;

  .LogoContainer {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      width: 650px;
    }
  }

  form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    .Inputs {
      display: flex;
      flex-direction: column;
      gap: 1em;

      input {
        width: auto;
      }
    }
  }

  .LoginBtn {
    padding: 0 16px 0 12px;
    width: -webkit-fill-available;
    border-radius: 5px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    background-color: #005e91;
    border: none;
    align-items: center;
    justify-content: center;
    justify-self: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    letter-spacing: 1px;

    &:hover {
      box-shadow: 0 5px 10px rgba(0,0,0,0.22);
      cursor: pointer;
    }
  }

  .LoginErrMsg {
    color: #E0323B;
  }
}
