@import './variables.scss';

.FormWrapper {
	display: grid;
	grid-template-rows: auto auto;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	margin: auto;
	padding: 32px;
	max-width: 850px;

  .FieldInputBox {
    place-self: center stretch;
    height: 28px;
    width: 50%;
  }

  	.FieldCheckBox {
		align-items: center;
		display: flex;
	}

  .box {
    grid-row: 1;
  }
  

	.FormHeader {
		display: grid;
		grid-template-columns: auto;
		grid-template-areas:
			'heading escbtn';
		width: 100%;
		margin: auto;
		padding-bottom: 24px;

		.Heading {
			grid-area: heading;
			justify-self: start;
		}

		.EscalateBtn {
			justify-self: end;
			@include SecondaryBtn;
			display: flex;
			flex-direction: row;
			align-items: center;
			column-gap: 8px;
      margin-right: 10px;

		}
	}

	.FieldGroupSingle {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas:
	    'fieldlbl'
		 	'fieldinput'
			'fieldinfo';
		width: 100%;
		grid-gap: 8px;
		margin-bottom: 24px;
		justify-items: center;
		align-items: start;
	}

  .FieldGroupSingleRow {
    grid-row: 1;
		grid-template-columns: 1fr;
		grid-template-areas:
	    'fieldlbl'
		 	'fieldinput'
			'fieldinfo';
		width: 100%;
		grid-gap: 8px;
		margin-bottom: 24px;
		justify-items: center;
		align-items: start;

    .PullLeft{
      position: absolute;
      left: 30px;
    }
	}

  .TermsWrapper {
          grid-column: 1 / span 2;
          p {
            line-height: 2;
          }
          input[type='checkbox'] {
            width: 25px;
            height: 25px;
            vertical-align: bottom;
            margin-right: 10px;
          }
          h3 {
            font-family: 'Roboto Condensed';
            font-size: 14pt;
            text-transform: uppercase;
            color: #47a3d3;
            font-weight: 700;
          }
          input.ContractUndersigned {
            width: 300px;
            height: 25px;
            padding: 0 8px;
            border: none;
            border-bottom: 2px solid $primary-text-75;
            justify-self: start;
            font-family: 'Roboto';
            font-size: 16px;
            color: $primary;
            justify-self: stretch;
          }
        }

  .column {
          flex-direction: column;
        }

	.PhoneInput {
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		position: relative;
		width: 100%;
		margin-top: 4% !important;
	}

	.PhoneInputSmaller {
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		position: relative;
		width: 100%;
		margin-top: 3% !important;
	}

	.FieldGroupDouble {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
	    'fieldgrpleft fieldgrpright';
		grid-gap: 24px;
		width: 100%;
		// margin-bottom: 10px;
		justify-items: center;
		align-items: start;
		.FieldGroupL {
			grid-area: fieldgrpleft;
			margin-bottom: 0;
			padding-right: 25px;
			width: -webkit-fill-available;
			div.react-tel-input  {
				font-family: 'Roboto', sans-serif;
				font-size: 15px;
				position: relative;
				width: 100%;
				margin-top: 4% !important;
			}
			
		}
		.FieldGroupR {
			grid-area: fieldgrpright;
			margin-bottom: 0;
			padding-left: 25px;
			width: -webkit-fill-available;
		}
	}
	

	.FieldGroupTripple {
		display: grid !important;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
	    'fieldgrpleft fieldgrpcenter fieldgrpright';
		grid-gap: 24px;
		width: 100% !important;
		margin-bottom: 10px;
		justify-items: center;
		align-items: start;
		.FieldGroupLL {
			grid-area: fieldgrpleft;
			margin-bottom: 0;
			padding-right: 15px;
			width: -webkit-fill-available;
		}
		.FieldGroupCC {
			grid-area: fieldgrpcenter;
			margin-bottom: 0;
			width: -webkit-fill-available;
		}
		.FieldGroupRR {
			grid-area: fieldgrpright;
			margin-bottom: 0;
			padding-left: 15px;
			width: -webkit-fill-available;
		}
	}

	.select {
		width: 104%;
	}

	.FieldGroupL {
		@extend .FieldGroupSingle;
		grid-area: fieldgrpleft;
		margin-bottom: 0;
	}

	.FieldGroupR {
		@extend .FieldGroupSingle;
		grid-area: fieldgrpright;
		margin-bottom: 0;
	}

	.FieldLabel {
		grid-area: fieldlbl;
		place-self: center stretch;
		font-weight: 700;
		width: 100%;
		span {
			color: $error-red;
		}
	}

	.ErrorMsg {
		color: $error-red;
		width: 100%;
		text-align: left;
	}

	.FieldInput {
		grid-area: fieldinput;
		place-self: center stretch;
		width: 100%;
	}

	.FieldInfo {
		grid-area: fieldinfo;
		justify-self: start;
		width: 100%;
		@include FieldInfo;
	}

	[class*="control"] {
		background: $white !important;
		box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15) !important;
		border-radius: 5px;
		color: $dark-grey;
		font-family: $body-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		border: transparent;
		padding: 8px 16px !important;
		width: 100% !important;
		height: auto !important;
		padding-left: 8px !important;
		border-bottom: 1pt solid #47a3d3 !important;



		[class*="ValueContainer"], [class*="Input"], [class*="placeholder"] {
			padding: 0;
			margin: 0;
		}
		[class*="Input"] input {
			box-shadow: none;
			color: transparent;
	    	height: auto;
		}
		[class*="multiValue"] {
			background-color: $primary;
			color: $white;
			border-radius: 25px;
	    margin: 0 4px 4px 0;
			div:first-child {
				color: $white;
				font-family: $body-font;
				font-size: 14px;
				padding: 4px 4px 4px 12px;
			}
			div:nth-child(2) {
				cursor: pointer;
				&:hover {
					background-color: $error-red;
					color: $white;
					border-top-right-radius: 25px;
					border-bottom-right-radius: 25px;
				}
			}
		}
	}
	[class*="select"] {
		width: 104%;
	}
	select{
		width: 104%;
		border-bottom: 1pt solid #47a3d3 !important;
	}
	[class*="menu"] {
		margin: 1px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.Editor {
    background: $light-grey;
  	box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
  	border-radius: 5px;
  	color: $dark-grey;
  	font-family: $body-font;
  	font-style: normal;
  	font-weight: normal;
  	font-size: 16px;
  	line-height: 19px;
  	border: transparent;
  	padding: 4px;
    justify-self: stretch;
    width: 100%;

    [class*="ql-toolbar"] {
      border: none;
      margin-right: 4px;

      [class*="ql-formats"] {
        display: inline-flex;
				gap: 2px;
        margin-bottom: 4px;
      }
      [class*="ql-picker"] {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15), -1px -1px 2px rgba(255, 255, 255, 0.78);
        background: $light-grey;
        border-radius: 5px;
        [class*="ql-picker-item"] {
          box-shadow: none;

        }
      }
    }
    [class*="ql-container"] {
      border: none;
      box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.78), inset 1px 1px 2px rgba(0, 0, 0, 0.15);
    	border-radius: 5px;
      padding: 8px;
      font-size: 16px;
      background: $white;
      [class*="ql-editor"] {
        background: transparent;
				&[class*="ql-blank"]::before {
          color: $dark-grey-50;
        }
      }
    }
  }

	.FieldGroupSingle_File {
		display: grid;
		grid-template-columns: auto;
		grid-template-areas:
			'uploadfilesbtn'
			'uploaded';
		width: 100%;
		grid-gap: 8px;
		margin-bottom: 24px;
		justify-items: flex-start;
		align-items: start;
	}

  .ActionBtn {
    justify-self: end;
    @include SecondaryBtn;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
	margin-top: 10px;
    
  }

	.FilesUpload {
    padding-top: 16px;
		grid-area: uploadfilesbtn;
		width: 100%;
    .FilesBtn {
      @include SecondaryBtn;
      padding: 8px 16px;
    }

    input {
      display: none;
      min-height: 36px;
    }
  }
	.UploadedFiles {
		grid-area: uploaded;
		width: 100%;
	}

	.SubmitBtn {
		@include PrimaryBtn;
		font-size: 16px;
		text-transform: uppercase;
		color: white;
		border: none;
		display: flex;
		align-items: center;
		gap: 8px;
		width: 25%;
		justify-content: center;
	}

	.AmendDeclineBtn {
		@include PrimaryBtn;
		font-size: 16px;
		text-transform: uppercase;
		color: white;
		border: none;
		display: flex;
		align-items: center;
		gap: 8px;
		justify-content: center;
	}

	.UploadDocsAlign {
		display: flex !important;
		gap: 5% !important;
	}

	.row {
		flex-direction: row;
	}

	.left {
		justify-self: start !important;
	}
}

.ButtonPanel {
	padding: 16px;
	color: $primary-text;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 16px;
	border-bottom: 2px solid $light-grey;
  
	.NavBtn {
		justify-self: end;
		@include SecondaryBtn;
		font-size: 16px;
		text-transform: uppercase;
		color: white;
		border: none;
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.PDFView {
	display: block;
	height: 100vh;
	width: 99.5vw;
	margin: auto;
}

.lineHeight29 {
	line-height: 29px;
	justify-self: normal;
}

.fixedFooter {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #f1f1f1;
	padding: 10px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}