// .Messages {
//   display: flex;
//   flex-direction: column;
//   max-height: 80vh;
//   overflow-y: auto;
//   max-width: 75%;

//   .Message {
//     display: flex;
//     flex-direction: column;
//     border: 1px solid black;
//     border-radius: 5px;
//     padding: 0.5em 1em;
//     margin: 0.25em 0;

//     .Body {
//       display: block;
//     }

//     .UserLine {
//       * {
//         margin: 0.5ex;
//       }
//     }
//   }
// }

@import '../../variables';

.MessageWrapper {
  display: grid;
  padding: 16px;
  grid-template-columns: auto;
  @include Panel;
  margin-top: 24px;
  grid-row-gap: 16px;
  .MessageHeader {
    font-family: $heading-font;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid $dark-grey-25;
    padding-bottom: 16px;
  }
  .MessageFooter {
    border-top: 1px solid $dark-grey-25;
    padding-top: 16px;
    font-size: 12px;
    text-transform: uppercase;
  }
}