@import '../../../variables.scss';

.ActiveTypeToggleContainer {
  display: flex;
  justify-content: center;

  div {
    display: flex;
    border: 1px solid black;

    button {
      background-color: lightgrey;
      padding: 0.5em 1em;
      border: 1px solid black;

      &.Active {
        background-color: $primary-darker-12;
        color: $primary-darker-12-text;
      }

      &:hover {
        background-color: $primary-lighter-12;
        color: $primary-lighter-12-text;
      }
    }
  }
}
