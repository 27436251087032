@import './variables';

 @media only screen and (max-width: 1024px) {

  [class*="control"] {
		background: $white;
	  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
	  border-radius: 5px;
	  color: $dark-grey;
		font-family: $body-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		border: transparent;
		padding: 8px 16px;
		width: 100%;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 40px;
    width: 108% !important;
  }

  .gridContainerGap {
    text-align: center;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    gap: 40px;
  }

  .gridContainer > div {
    text-align: center;
  }

  .gridContainer > .FieldLayoutGrid {
    grid-column: 1 / span 1;
  }

  .gridContainer > .FieldLayoutEndGrid {
    grid-column: 2 / span 1;
  }

  .FieldCheckBox {
		align-items: center;
		display: flex;
    height: 35px;
	}

  .FieldHeader {
    font-family: 'Roboto Condensed';
    font-size: 14pt;
    text-transform: uppercase;
    color: #47a3d3;
    font-weight: 700;
  }

  .SubButton {
    @include FABtn;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .input {
    width: 80%;
    padding-top: 2%;
    &::placeholder {
      color: $dark-grey-50;
    }
  }

  .InputBox {
    height: 35px;
    width: 86%;
    &::placeholder {
      color: $dark-grey-50;
    }
  }

  .FieldGroupSingle_File {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
      'uploadfilesbtn'
      'uploaded';
    width: 100%;
    grid-gap: 8px;
    margin-bottom: 8px;
    justify-items: flex-start;
    align-items: start;
  }
  
  .FilesUpload {
    padding-top: 5px;
    grid-area: uploadfilesbtn;
    width: 90%;
    .FilesBtn {
      @include SecondaryBtn;
      padding: 8px 16px;
    }
    input {
      display: inline;
      min-height: 36px;
    }
  
    .UploadWarningBtn {
      @include ButtonPanel;
      background-color: $error-red;
      padding-top: 5%;
    }
  
    .UploadSucessBtn {
      @include ButtonPanel;
      background-color: $success-green;
      padding-top: 5%;
    }
  }

  .ErrorMsg {
    color: red;
  }

   //NAVBAR
  .Nav {
    display: grid;
    grid-direction: column;
    grid-template-columns: 1fr auto min-content;
    grid-gap: 8px;
    grid-template-areas:
    'logo navlinks morebtn';
    width: 100%;
    justify-items: start;
    align-items: center;
    padding: 8px 16px;

    height: 58px;
    background-color: $light-grey;
    box-shadow: -3px -3px 7px rgba(255, 255, 255, 0.78), 3px 3px 7px rgba(0, 0, 0, 0.15);
    color: $dark-grey;
    font-family: $heading-font;
    position: sticky;
    top: 0;
    z-index: 2;

    .NavLogo {
      grid-area: logo;
      img {
        width: 40px;
    		max-width: 40px;
    	}
    }

    .NavLinks {
      grid-area: navlinks;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-gap: 4px;
      justify-self: end;
  		a {
  			display: flex;
  			align-items: center;
  			@include MenuBtnMobile;
        justify-content: center;
        .WithIndicator {
          padding-left: 4px;
        }
        .MsgIndicator {
          height: 5px;
        	width: 5px;
        	border-radius: 6px;
          background-color: $dark-grey;
          position: relative;
          top: -6px;
        }
        .HelpNav {
          font-size: 16px;
        }
        &.active {
          background: linear-gradient(90deg, #006CA7 0%, #0080C5 100%);
          box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.31), inset 2px 2px 2px rgba(0, 0, 0, 0.15);
          color: $white;

          .MsgIndicator {
            background-color: $white;
          }
        }

      }
  	}

  .MoreMenuBtn {
    grid-area: morebtn;
    @include MenuBtnMobile;
    display: grid;
    justify-self: end;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    color: black;
    //grid-column: 1 /5;
  }
  .MuiPopover-root {
    right: 0;
    left: auto;
    .MuiPaper-root {
      top: 56px !important;
      right: 16px !important;
      left: auto !important;
    }
  }
  .NewTicket {
    position: absolute;
    top: 90vh;
    right: 16px;
    button {
      @include FABtn;
      display: grid;
      justify-content: center;
      align-items: center;
    }
  }
}
  //ISSUE LIST
  .IssueOptionsToolbar {
    display: grid;
    padding: 16px;
    grid-template-columns: auto auto auto;
    grid-template-areas:
      'heading search downloadbtn';
    width: 100%;
    margin: auto;
    justify-content: start;
    place-items: center;

    .Heading {
  		grid-area: heading;
      margin-right: 8px;
  	}

    .Search {
      grid-area: search;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas:
        'inputfield searchicon';
      place-items: center;

      input {
        grid-area: inputfield;
        &::placeholder {
          color: $dark-grey-50;
        }
      }
      i {
        grid-area: searchicon;
        position: relative;
        left: -30px;
        color: $dark-grey-50;
        width: 0;
      }
    }

    /*.DownloadBtn {
      grid-area: downloadbtn;
      justify-self: end;
    	@include MenuBtnMobile;
      display: grid;
      justify-content: center;
      align-items: center;
    }*/
  }

  .DownloadCsvBtn {
    @include FABtn;
    position: fixed;
    font-size: 26px;
    width: 46px;
  	height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80%;
    right: 5%;
  }
  .DownloadPdfBtn {
    @include FABtn;
    position: fixed;
    font-size: 26px;
    width: 46px;
  	height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 90%;
    right: 5%;
  }

  //CARDS
  .CardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0 16px 16px 16px;
    grid-gap: 16px;

    .IssueCard {
      display: grid;
      padding: 16px;
      grid-template-columns: auto;
      @include Panel;
      grid-gap: 8px;
      height: fit-content;
      cursor: pointer;

      .CardFirstRow {
        display: grid;
        grid-template-columns: auto auto;
        p {
          margin-bottom: 0;
        }
        .Priority {
          text-align: right;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      h1 {
        color: $dark-grey;
        font-weight: normal;
      }
      .CardCol {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 8px;
        .CardRow {
          i {
            color: $dark-grey-50;
            vertical-align: bottom;
            padding-right: 8px;
            display: inline-block;
          }
          .field {
              font-weight: 600;
              padding-right: 8px;
              display: inline-block;
          }
          .value {
            color: $primary;
            span {
              cursor: default;
              //cursor: help;
            }
          }
          h1 {
            display: inline;
            font-size: 1.1em;
            line-height: 1.1em;
          }
        }
      }
    }
  }

  //ISSUE
  .BackRow {
    padding: 16px;
    .TertiaryBtn {
      @include TertiaryBtn;
      padding-left: 0;
      i {
        vertical-align: middle;
      }
    }
  }

  .PageWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
      'overviewpanel'
      'detailspanel';
    width: 80%;
    padding: 0 16px 16px 6%;
    grid-gap: 60px;

    .OverviewPanel {
      grid-area: overviewpanel;
      display: grid;
      padding: 16px;
      padding-left: 8%;
      margin-top: 16px;
      width: 100%;
      grid-template-columns: auto;
      @include Panel;
      grid-gap: 16px;
      height: fit-content;

      .OverviewFirstRow {
        display: grid;
        grid-template-columns: auto auto;
        h1 {
          word-wrap: normal;
        }
        .Priority {
          text-align: right;
          font-size: 12px;
          text-transform: uppercase;
        }
        p {
          margin-bottom: 0;
        }
      }
      .OverviewRow {
        i {
          color: $dark-grey-50;
          vertical-align: bottom;
          padding-right: 8px;
          display: inline;
        }
        .field {
            font-weight: 600;
            padding-right: 8px;
            display: inline;
        }
        .value {
          color: $primary;
          display: inline;
          span {
            cursor: default;
          }
        }
      }
      .EscalateBtn {
        @include EscalateBtn;
        width: 100%;
        i {
          vertical-align: bottom;
        }
      }
    }
    .SubmitButton {
      @include PrimaryBtn;
      margin-top: 20px;
      width: 110%;
    }

    .EscalateBtn {
      @include EscalateBtn;
      margin-top: 10px;
      width: 110%;
      i {
        vertical-align: bottom;
      }
    }

    .DetailsPanel {
      grid-area: detailspanel;
      display: grid;
      grid-template-columns: auto;
      grid-row-gap: 16px;
      height: fit-content;
      padding-bottom: 16px;

      .DetailsFirstRow {
        display: grid;
        border-bottom: 2px solid $dark-grey-25;
        padding: 16px 0;
        grid-row-gap: 16px;
        h2 {
          margin-bottom: 8px;
        }
      }
      .DetailsRow {
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 16px;
      }
    }
  }

  //SURVEYLIST
  .OptionsToolbar {
    display: grid;
  	padding: 16px;
    width: 100%;
    row-gap: 8px;

    .Heading {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas:
    		'heading1 downloadbtn';
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-gap: 16px;
      justify-self: stretch;

      h1 {
        grid-area: heading1;
      }
      .DownloadBtn {
        justify-self: end;
        @include MenuBtnMobile;
        display: grid;
        justify-content: center;
        align-items: center;
      }
    }

    .SearchWord {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas:
        'inputfield inputicon';
      place-items: start;
      justify-self: stretch;
      width: 100%;

      input {
        grid-area: inputfield;
        &::placeholder {
          color: $dark-grey-50;
        }
      }
      i {
        grid-area: inputicon;
        position: relative;
        left: -30px;
        top: 12px;
        color: $dark-grey-50;
      }
    }

  }

  input[type='checkbox'] {
    width: 25px;
    height: 25px;
    vertical-align: center;
    margin-left: auto;
  }

  [class*="multiValue"] {
    background-color: $primary;
    color: $white;
    border-radius: 25px;
    margin: 0 4px 4px 0;
    div:first-child {
      color: $white;
      font-family: $body-font;
      font-size: 14px;
      padding: 4px 4px 4px 12px;
    }
    div:nth-child(2) {
      cursor: pointer;
      &:hover {
        background-color: $error-red;
        color: $white;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }

  .TermsWrapper {
    p {
      line-height: 2;
    }
    input[type='checkbox'] {
      width: 25px;
      height: 25px;
      vertical-align: bottom;
      margin-right: 10px;
    }
    h3 {
      font-family: 'Roboto Condensed';
      font-size: 14pt;
      text-transform: uppercase;
      color: #47a3d3;
      font-weight: 700;
    }
    input.ContractUndersigned {
      width: 80%;
      height: 25px;
      padding: 0 8px;
      border: none;
      border-bottom: 2px solid $primary-text-75;
      justify-self: start;
      font-family: 'Roboto';
      font-size: 16px;
      color: $primary;
      justify-self: stretch;
    }
  }

  .TicketFeedbackResponse {
    display: grid;
    grid-template-rows: auto;
    column-gap: 16px;
    row-gap: 4px;

  }

  .fixedFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 98%;
    background-color: #f1f1f1;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*
  .ResponsesWrapper {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    padding: 0 16px 16px 16px;
    grid-gap: 16px;
  }

  .ResponseCard {
    display: grid;
    padding: 16px;
    grid-template-columns: auto;
    @include Panel;
    grid-gap: 8px;
    height: fit-content;
    cursor: pointer;

    .CardFirstRow {
      display: grid;
      grid-template-columns: auto auto;
      p {
        margin-bottom: 0;
      }
      .Priority {
        text-align: right;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .CardCol {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 8px;
      .CardRow {
        i {
          color: $dark-grey-50;
          vertical-align: bottom;
          padding-right: 8px;
          display: inline-block;
        }
        .field {
            font-weight: 600;
            padding-right: 8px;
            display: inline-block;
        }
        .value {
          color: $primary;
          td {
            cursor: default;
            //cursor: help;
          }
        }
      }
    }
  }*/



 }

 @media only screen and (max-width: 480px) {

 }
