$primary: #0081c6;
$primary-light: #0081c60a;
$primary-darker-12: scale-color($primary, $lightness: -12%);
$primary-darker-12-text: white;
$primary-lighter-12: scale-color($primary, $lightness: 12%);
$primary-lighter-12-text: black;
$primary-lighter-84: scale-color($primary, $lightness: 84%);
$primary-lighter-84-text: black;
$primary-lighter-96: scale-color($primary, $lightness: 96%);
$primary-lighter-96-text: black;

$primary-teal: #26A18E;
$primary-green: #44AF69;
$primary-yellow: #F09021;
$primary-orange: #F15F22;
$primary-red: #E0323B;
$light-grey: #eeeeee;

$primary-text: black;
$primary: #0081c6;
$primary-text-75: scale-color($primary-text, $lightness: 25%);
$primary-text-lighter: scale-color($primary-text, $lightness: 50%);
$primary-darker-12-text: white;
$primary-darker-12: scale-color($primary, $lightness: -12%);
$primary-darker-24-text: white;
$primary-darker-24: scale-color($primary, $lightness: -24%);
$primary-lighter-12-text: black;
$primary-lighter-12: scale-color($primary, $lightness: 12%);
$primary-lighter-24-text: black;
$primary-lighter-24: scale-color($primary, $lightness: 24%);
$primary-lighter-84-text: black;
$primary-lighter-84: scale-color($primary, $lightness: 84%);
$primary-lighter-96-text: black;
$primary-lighter-96: scale-color($primary, $lightness: 96%);
$error-text: darken(#f00, 8);
$error-background: lighten($error-text, 50);
$success-text: darken(#0f0, 16);
$success-background: lighten($success-text, 50);

/* NEUMORPHISM SKIN */
$primary: #0080C5;
$dark-grey: #505050;
$dark-grey-75: rgba(80, 80, 80, 0.75);
$dark-grey-50: rgba(80, 80, 80, 0.5);
$dark-grey-25: rgba(80, 80, 80, 0.25);
$white: #ffffff;
$white-gradient: linear-gradient(135deg, #FEFDFD 0%, rgba(220, 220, 220, 0.5) 100%);
$light-grey: #F4F4F4;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Roboto Condensed', sans-serif;
$error-red: #E0323B;
$warning-orange: #F09021;
$success-green: #44AF69;
$black: #000000;


@mixin Button {
	height: 36px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15), -1px -1px 2px rgba(255, 255, 255, 0.78);
	border-radius: 66px;
	border: transparent;
	font-family: $heading-font;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	padding: 0 16px;
	cursor: pointer;
	text-decoration: none;
}

@mixin PrimaryBtn {
	@include Button;
	color: $white;
	background-color: $primary;
	&:hover {
		background-color: $dark-grey;
	}
	.material-icons {
		vertical-align: middle;
	}
}

@mixin SecondaryBtn {
	@include Button;
	color: $white;
	background: $dark-grey;
	&:hover {
		color: $white;
		background-color: $primary;
	}
	.material-icons {
		vertical-align: middle;
	}
}

@mixin TertiaryBtn {
	@include Button;
	color: $primary;
	background: transparent;
	box-shadow: none;
	font-size: 14px;
	.material-icons {
		vertical-align: middle;
	}
}

@mixin WarningBtn {
	@include Button;
	color: $white;
	background: $error-red;
	&:hover {
		color: $white;
		background-color: $error-red;
	}
	.material-icons {
		vertical-align: middle;
	}
}

@mixin SucessBtn {
	@include Button;
	color: $white;
	background: $success-green;
	&:hover {
		color: $white;
		background-color: $success-green;
	}
	.material-icons {
		vertical-align: middle;
	}
}

@mixin EscalateBtn {
	@include SecondaryBtn;
	.material-icons {
		color: $white;
	}
}

@mixin ButtonPanel {
	background: $light-grey;
	box-shadow: inset -3px -3px 7px rgba(255, 255, 255, 0.78), inset 3px 3px 7px rgba(0, 0, 0, 0.15);
	border-radius: 50px;
	padding: 4px;
}

@mixin MsgIndicator {
	height: fit-content;
	width: fit-content;
	min-height: 15px;
	min-width: 15px;
	border-radius: 15px;
	padding: 1px 5px;
	position: relative;
	top: -5px;
	right: -5px;
	color: $white;
	background-color: $dark-grey;
	text-align: center;
	font-size: 10px;
	font-weight: bolder;
}

@mixin MenuBtn {
	@include Button;
	color: $dark-grey;
	box-shadow: none;

	.material-icons {
		margin-right: 8px;
	}
	.MsgIndicator {
		@include MsgIndicator;
	}
	&.active {
		color: $white;
		background-color: $primary;
		box-shadow: -3px -3px 7px rgba(255, 255, 255, 0.78), 3px 3px 7px rgba(0, 0, 0, 0.15);
		.MsgIndicator {
			background-color: $white;
			color: $primary;
		}
		&:hover {
			color: $white;
			background-color: $primary;
		}
	}
	&:hover {
		color: $white;
		background-color: $primary;
		box-shadow: -3px -3px 7px rgba(255, 255, 255, 0.78), 3px 3px 7px rgba(0, 0, 0, 0.15);
		.MsgIndicator {
			background-color: $white;
			color: $primary;
		}
	}
}

@mixin MenuBtnMobile {
	background: $light-grey;
	box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 66px;
	width: 10%;
	height: 40px;
	color: $dark-grey;
	text-decoration: none;
	&:hover {
		color: $white;
		background-color: $primary;
		cursor: pointer;
	}
}

@mixin FieldInfo {
	color: $dark-grey-75;
	font-size: 12px;
	font-style: italic;
	font-family: $body-font;
}

@mixin FABtn {
	@include MenuBtnMobile;
	font-size: 36px;
	width: 56px;
	height: 56px;
	background: $primary;
	color: $white;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
	&:hover {
		color: $primary;
		background-color: $white;
	}
}

@mixin Panel {
	background: $light-grey;
	//box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
	box-shadow: -3px -3px 7px rgba(255, 255, 255, 0.78), 3px 3px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
}

@mixin Select {
	height: 48px;
  background: $white;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.78), 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: $dark-grey;
	font-family: $body-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	border: transparent;
	padding: 0 16px;
  justify-self: stretch;
  width: 100%;

  &::placeholder {
		color: $dark-grey-50;
	}
	&:focus-visible {
		outline: 1px solid $primary;
	}
}
