.LogsPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  .LogsList {
    display: flex;
    flex: 1;

    div {
      display: flex;
      align-items: center;

      pre {
        text-align: start;
        margin: 0 0.5em;
        white-space: nowrap;
      }

      span {
        color: lightgrey;
        padding: 0 0.5em;
        white-space: nowrap;
      }

      .stderr {
        color: red;
      }
    }
  }
}
