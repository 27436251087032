@import '../../variables';


.FieldCheckBox {
	place-self: center stretch;
	height: 20px;
    width: 20px !important;
}

.FieldHeader {
	font-family: 'Roboto Condensed';
    font-size: 14pt;
    text-transform: uppercase;
    color: #47a3d3;
    font-weight: 700;
}

.FieldHeaderMargin {
	margin: unset;
	justify-self: flex-start !important;
}

.FieldLayoutGrid {
	grid-row-start: 1;
	grid-row-end: 4;
}

.FieldLayoutEndGrid {
	width: 100%;
	grid-column-start: none;
}

.FieldAccountType {
	grid-row-start: 1;
	grid-row-end: 4;
}

.FieldDebitOrderDate {
	grid-area: 1 / 4;
}

.FieldSeenSocialMedia {
	width: 30%;
}

// .gridContainer {
// 	display: grid;
// 	grid-template-columns: auto auto auto auto;
// 	width: 100%;
// 	.row {
// 		flex-direction: column;
// 	}
// }

.gridContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	padding: 8px;
	gap: 8px;
	display: block;
	.row_1 {
		grid-row: 1 / 5;
		grid-column: 1 / 5;
	}
	.row_2 {
		grid-column: 2
	}
  }

.ThreegridContainer {
	display: grid;
	grid-template-columns: auto auto auto;
	width: 100%;
}

.gridContainerGap {
	text-align: center;
	display: grid;
	grid-template-columns: auto auto auto;
	width: 100%;
	gap: 40px;
}

.gridContainer > div {
	text-align: center;
}

.width108 {
	width: 108% !important;
	border-bottom: 1pt solid #47a3d3 !important;
}

.SubmitBtns {
	@include PrimaryBtn;
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	color: white;
	border: none;
	display: flex;
	align-items: center;
	gap: 8px;
	width: 104%;
	justify-content: center;
	margin-left: 4%;
	border-block-end-width: inherit;
}

.ErrorMsg {
	color: $error-red;
}